<template>
  <div class="content" @click="closeAllModal">
    <div class="container">
      <form class="form-wrapper">
        <div class="form-main">
          <div id="fill" class="form-main__title">
            <span class="form-main__title--pointer">{{
              $t('main_page.text.form_title')
            }}</span>
          </div>
          <div class="form-main__text">
            {{ $t('main_page.text.form_subtitle') }}
          </div>
          <div class="form-main__text-attention">
            <svg-icon name="attention-main"></svg-icon>
            <div class="at-text">
              {{ $t('main_page.text.attention_latin') }}
            </div>
          </div>

          <form class="form-main-fields">
            <div class="form-main-fields__inputs-grid">
              <div class="input__name">
                <input
                  v-model="name"
                  type="text"
                  class="form-main-fields__inputs"
                  :placeholder="$t('data_naming.name')"
                  :class="{
                    invalid:
                      ($v.name.$dirty && !$v.name.required) ||
                      ($v.name.$dirty && !$v.name.minLength) ||
                      ($v.name.$dirty && !$v.name.alpha),
                  }"
                />
                <div
                  v-if="$v.name.$dirty && !$v.name.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div v-if="$v.name.$dirty && !$v.name.alpha" class="fill-error">
                  {{ $t('main_page.latin_letters') }}
                </div>
              </div>

              <div class="input__surname">
                <input
                  v-model="surname"
                  type="text"
                  class="form-main-fields__inputs"
                  :placeholder="$t('data_naming.surname')"
                  :class="{
                    invalid:
                      ($v.surname.$dirty && !$v.surname.required) ||
                      ($v.surname.$dirty && !$v.surname.alpha) ||
                      ($v.surname.$dirty && !$v.surname.minLength),
                  }"
                />
                <div
                  v-if="$v.surname.$dirty && !$v.surname.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="$v.surname.$dirty && !$v.surname.alpha"
                  class="fill-error"
                >
                  {{ $t('main_page.latin_letters') }}
                </div>
              </div>

              <div class="input__email">
                <input
                  v-model.trim="email"
                  type="email"
                  class="form-main-fields__inputs"
                  :class="{
                    invalid:
                      ($v.email.$dirty && !$v.email.required) ||
                      ($v.email.$dirty && !$v.email.email),
                  }"
                  :placeholder="$t('data_naming.email')"
                />
                <div
                  v-if="$v.email.$dirty && !$v.email.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="$v.email.$dirty && !$v.email.email"
                  class="fill-error"
                >
                  {{ $t('main_page.correct_email') }}
                </div>
              </div>

              <div class="passport-input">
                <input
                  v-model="passport_number"
                  type="text"
                  class="form-main-fields__inputs"
                  :class="{
                    invalid:
                      ($v.passport_number.$dirty &&
                        !$v.passport_number.required) ||
                      ($v.passport_number.$dirty &&
                        !$v.passport_number.alphaNum),
                  }"
                  :placeholder="$t('data_naming.passport_number')"
                />
                <div
                  v-if="
                    $v.passport_number.$dirty && !$v.passport_number.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="
                    $v.passport_number.$dirty && !$v.passport_number.alphaNum
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.latin_letters') }}
                </div>
              </div>

              <div class="input__nationality-wrapper">
                <SelectField
                  v-model="nationality_country"
                  :items="countries"
                  :placeholder="$t('data_naming.nationality')"
                  :class="{
                    invalid:
                      $v.nationality_country.$dirty &&
                      !$v.nationality_country.required,
                  }"
                >
                </SelectField>
                <div
                  v-if="
                    $v.nationality_country.$dirty &&
                    !$v.nationality_country.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>
              <div class="input__residence-wrapper">
                <SelectField
                  v-model="residence_country"
                  :placeholder="$t('data_naming.residence_country')"
                  is-conutry-code
                  :items="countries"
                  class="form-main-fields__country-input"
                  :class="{
                    invalid:
                      $v.residence_country.$dirty &&
                      !$v.residence_country.required,
                  }"
                ></SelectField>
                <div
                  v-if="
                    $v.residence_country.$dirty &&
                    !$v.residence_country.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>

              <div class="phone-input-wrapper">
                <label
                  class="phone-input"
                  for="id-phone-input"
                  @click.stop="phoneInputWindowOpen"
                >
                  <svg-icon
                    class="id-phone-input__flag-icon"
                    :name="phoneNumber.phoneCode.id"
                  ></svg-icon>
                  <input
                    id="id-phone-input"
                    v-model="searchCodeTitle"
                    placeholder="+380"
                    autocomplete="off"
                    :class="{
                      invalid:
                        $v.searchCodeTitle.$dirty &&
                        !$v.searchCodeTitle.required,
                      'invalid-phone-code': !phoneNumber.numberValue,
                    }"
                    type="text"
                  />
                  <svg-icon
                    class="phone-input__arrow"
                    :class="cssClasses"
                    name="select-caret"
                  ></svg-icon>
                </label>
                <input
                  v-model="phoneNumber.numberValue"
                  class="phone-input__number"
                  :class="{
                    invalid:
                      $v.phoneNumber.numberValue.$dirty &&
                      !$v.phoneNumber.numberValue.required,
                  }"
                  type="number"
                  :placeholder="$t('data_naming.phone_number')"
                />
                <ul v-if="phoneInputWindow" class="phone-input__list">
                  <li
                    v-for="code in filtredTitleCodes"
                    :key="code.id"
                    :class="{ selected: searchCodeTitle === code.title }"
                    @click="phoneInputHandler(code)"
                  >
                    <svg-icon :name="`${code.id.toUpperCase()}`"></svg-icon>
                    {{ code.title }}
                  </li>
                </ul>
                <div
                  v-if="
                    ($v.phoneNumber.numberValue.$dirty &&
                      !$v.phoneNumber.numberValue.required) ||
                    ($v.searchCodeTitle.$dirty && !$v.searchCodeTitle.required)
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>
              <div class="phone-input-wrapper">
                <label
                  class="phone-input"
                  for="alter-id-phone-input"
                  @click.stop="alternatePhoneInputWindowOpen"
                >
                  <svg-icon
                    class="id-phone-input__flag-icon"
                    :name="alternatePhoneNumber.phoneCode.id"
                  ></svg-icon>
                  <input
                    id="alter-id-phone-input"
                    v-model="alternateSearchCodeTitle"
                    placeholder="+380"
                    autocomplete="off"
                    type="text"
                  />
                  <svg-icon
                    class="alternate-phone-input__arrow"
                    :class="cssClasses"
                    name="select-caret"
                  ></svg-icon>
                </label>
                <ul v-if="alternatePhoneInputWindow" class="phone-input__list">
                  <li
                    v-for="alterCode in alternateFiltredTitleCodes"
                    :key="alterCode.id"
                    :class="{
                      selected: alternateSearchCodeTitle === alterCode.title,
                    }"
                    @click.stop="alternatePhoneInputHandler(alterCode)"
                  >
                    <svg-icon
                      :name="`${alterCode.id.toUpperCase()}`"
                    ></svg-icon>
                    {{ alterCode.title }}
                  </li>
                </ul>
                <input
                  v-model="alternatePhoneNumber.numberValue"
                  class="phone-input__number"
                  type="number"
                  :placeholder="$t('data_naming.alternate_phone_number')"
                />
                <div class="optional">
                  {{ $t('main_page.optional') }}
                </div>
              </div>
            </div>

            <div class="form-main-fields__inputs-grid">
              <div class="port-input">
                <input
                  v-model="port_of_departure"
                  type="text"
                  class="form-main-fields__inputs"
                  :class="{
                    invalid:
                      ($v.port_of_departure.$dirty &&
                        !$v.port_of_departure.required) ||
                      ($v.port_of_departure.$dirty &&
                        !$v.port_of_departure.alphaNum),
                  }"
                  :placeholder="$t('data_naming.port_of_departure')"
                />
                <div
                  v-if="
                    $v.port_of_departure.$dirty &&
                    !$v.port_of_departure.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="
                    $v.port_of_departure.$dirty &&
                    !$v.port_of_departure.alphaNum
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.text.attention_latin') }}
                </div>
              </div>

              <div class="port-arrival-input">
                <SelectField
                  v-model="port_of_arrival"
                  :value="codes.id"
                  :placeholder="$t('data_naming.port_of_arrival')"
                  :items="regions"
                  :class="{
                    invalid:
                      $v.port_of_arrival.$dirty && !$v.port_of_arrival.required,
                  }"
                ></SelectField>
                <div
                  v-if="
                    $v.port_of_arrival.$dirty && !$v.port_of_arrival.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>

              <div
                class="date-arrival-picker-input"
                :class="{
                  invalid: $v.arrival_date.$dirty && !$v.arrival_date.required,
                }"
              >
                <DatePicker
                  v-model="arrival_date"
                  :editable="false"
                  :lang="lang"
                  :placeholder="$t('data_naming.arrival_date')"
                  value-type="format"
                >
                </DatePicker>
                <div
                  v-if="$v.arrival_date.$dirty && !$v.arrival_date.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>

              <div
                class="date-arrival-picker-input--small"
                :class="{
                  invalid: $v.arrival_date.$dirty && !$v.arrival_date.required,
                }"
              >
                <DatePicker
                  v-model="arrival_date"
                  :lang="lang"
                  :editable="false"
                  value-type="format"
                  :placeholder="$t('data_naming.arrival_date_small')"
                ></DatePicker>
                <div
                  v-if="$v.arrival_date.$dirty && !$v.arrival_date.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>

              <div class="departure-input">
                <div class="date-departure-picker-input">
                  <DatePicker
                    v-model="departure_date"
                    :lang="lang"
                    :editable="false"
                    :placeholder="$t('data_naming.departure_date')"
                    value-type="format"
                  >
                  </DatePicker>
                </div>
                <div class="optional">
                  {{ $t('main_page.optional') }}
                </div>
                <div class="date-departure-picker-input--small">
                  <DatePicker
                    v-model="departure_date"
                    :lang="lang"
                    :editable="false"
                    value-type="format"
                    :placeholder="$t('data_naming.departure_date_small')"
                  ></DatePicker>
                </div>
              </div>

              <div class="transport-type-input">
                <SelectField
                  v-model="type_of_transport"
                  :placeholder="$t('data_naming.type_of_transport')"
                  :items="types"
                  :class="{
                    invalid:
                      $v.type_of_transport.$dirty &&
                      !$v.type_of_transport.required,
                  }"
                ></SelectField>
                <div
                  v-if="
                    $v.type_of_transport.$dirty &&
                    !$v.type_of_transport.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>

              <div class="flight-input">
                <input
                  v-model="transport_number"
                  type="text"
                  class="form-main-fields__inputs"
                  :class="{
                    invalid:
                      ($v.transport_number.$dirty &&
                        !$v.transport_number.required) ||
                      ($v.transport_number.$dirty &&
                        !$v.transport_number.alphaNum),
                  }"
                  :placeholder="$t('data_naming.transport_number')"
                />
                <div
                  v-if="
                    $v.transport_number.$dirty && !$v.transport_number.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="
                    $v.transport_number.$dirty && !$v.transport_number.alphaNum
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.text.attention_latin') }}
                </div>
              </div>
            </div>

            <div class="form-main-fields__inputs-grid">
              <div class="trip__input">
                <SelectField
                  v-model="trip_target"
                  :placeholder="$t('data_naming.trip_target')"
                  :class="{
                    invalid: $v.trip_target.$dirty && !$v.trip_target.required,
                  }"
                  :items="trip_aims"
                ></SelectField>
                <div
                  v-if="$v.trip_target.$dirty && !$v.trip_target.required"
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
              </div>
              <div class="accommodation__input">
                <input
                  v-model="accommodation_address"
                  type="text"
                  class="form-main-fields__inputs"
                  :class="{
                    invalid:
                      ($v.accommodation_address.$dirty &&
                        !$v.accommodation_address.required) ||
                      ($v.accommodation_address.$dirty &&
                        !$v.accommodation_address.alphaNum),
                  }"
                  :placeholder="$t('data_naming.accommodation_address')"
                />
                <div
                  v-if="
                    $v.accommodation_address.$dirty &&
                    !$v.accommodation_address.required
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.necessary_field') }}
                </div>
                <div
                  v-if="
                    $v.accommodation_address.$dirty &&
                    !$v.accommodation_address.alphaNum
                  "
                  class="fill-error"
                >
                  {{ $t('main_page.text.attention_latin') }}
                </div>
              </div>
            </div>

            <div class="form__btns">
              <div class="btn-add-test" :disabled="pcr_test_file">
                <label
                  class="form__btn form__btn--add-test"
                  :class="{ disable: pcr_test_file }"
                  :disabled="pcr_test_file"
                  for="file-pcr-test"
                >
                  <span v-if="pcr_test_file">{{ pcr_test_file_name }}</span>
                  <span v-else>{{ $t('main_page.buttons.add_pcr') }}</span>
                  <input
                    id="file-pcr-test"
                    ref="file_pcr"
                    type="file"
                    name="file-pcr-test"
                    :disabled="pcr_test_file"
                    @input="changeUploadFile"
                  />
                </label>
                <span
                  v-if="pcr_test_file"
                  class="deleted-cross"
                  @click="deleteFilePcrTest"
                ></span>
              </div>

              <div
                class="btn-add-certificate"
                :disabled="certificate_file_name"
              >
                <label
                  class="form__btn form__btn--add-certificate"
                  :class="{ disable: certificate_file_name }"
                  :disabled="certificate_file_name"
                  for="file-certificate"
                >
                  <span v-if="certificate_file_name">{{
                    certificate_file_name
                  }}</span>
                  <span v-else>
                    {{ $t('main_page.buttons.certificate') }}
                  </span>
                  <input
                    id="file-certificate"
                    ref="file_certificate"
                    type="file"
                    name="file-certificate"
                    :disabled="certificate_file_name"
                    @input="changeCertificateFile"
                  />
                </label>
                <span
                  v-if="certificate_file_name"
                  class="deleted-cross"
                  @click="deleteFileCertificate"
                ></span>
              </div>
            </div>

            <div class="form__sign-contract">
              <a class="form__btn-sign-contract" @click="sendForm">
                {{ $t('main_page.buttons.sign_contract') }}
              </a>
            </div>
          </form>
        </div>
      </form>
    </div>
    <Popup :dialog.sync="modal">
      <div class="modal-verification">
        <svg-icon name="verification-message"></svg-icon>

        <button class="modal__title">
          {{ $t('popup.title') }}
        </button>

        <div class="modal__text">
          {{ $t('popup.check_email_1') }}
          <span class="modal__email">{{ email }}</span>
          {{ $t('popup.check_email_2') }}
        </div>

        <div class="modal__inputs">
          <input
            id="startVerifytInput"
            v-model="startInput"
            maxlength="2"
            class="modal__code"
            :class="{ incorrect: error }"
            placeholder="_ _"
            type="number"
          />
          <input
            id="secondVerifytInput"
            v-model="secondInput"
            maxlength="2"
            class="modal__code"
            :class="{ incorrect: error }"
            placeholder="_ _"
            type="number"
          />
          <input
            id="endVerifytInput"
            v-model="thirdInput"
            maxlength="2"
            class="modal__code"
            :class="{ incorrect: error }"
            placeholder="_ _"
            type="number"
          />
          <div v-if="error" class="incorect_message">
            {{ $t('popup.incorrect_code') }}
          </div>
        </div>

        <button
          class="modal__button"
          :disabled="verificationValidation.length !== 6"
          @click="goToSuccessPdf"
        >
          <Loader v-if="loader"></Loader>
          <span v-else> {{ $t('popup.verify') }} </span>
        </button>

        <div class="modal__text-attention">
          <div class="modal__text-attention-text">
            <svg-icon name="attention" class="modal__attention-icon"></svg-icon>
            {{ $t('popup.info_about_spam') }}
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import {
  email,
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z ]*$/);
const alphaNum = helpers.regex('alpha', /^[A-Za-z0-9 ]*$/);

import SelectField from '@/elements/Inputs/SelectField.vue';
import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';
import DatePicker from 'vue2-datepicker';
// import TextInputs from '@/components/TextInputs/TextInputs.vue';

export default {
  components: {
    SelectField,
    Popup,
    DatePicker,
    Loader,
    // TextInputs,
  },
  data() {
    return {
      lang: 'en',
      phoneInputWindow: false,
      alternatePhoneInputWindow: false,
      loader: false,
      startInput: '',
      secondInput: '',
      thirdInput: '',

      error: false,
      modal: false,

      phoneNumber: {
        phoneCode: {
          id: 'UA',
          title: '',
        },
        numberValue: '',
      },

      alternatePhoneNumber: {
        phoneCode: {
          id: 'UA',
          title: '',
        },
        numberValue: '',
      },

      email: '',
      name: '',
      surname: '',

      nationality_country: '',
      residence_country: '',
      countries: [],

      port_of_departure: '',
      port_of_arrival: '',
      regions: [],

      arrival_date: '',
      departure_date: '',

      passport_number: '',

      type_of_transport: '',
      types: [],

      transport_number: '',

      trip_target: '',
      trip_aims: [],

      accommodation_address: '',

      codes: [],
      searchCodeTitle: '',
      alternateSearchCodeTitle: '',
      pcr_test_file: null,
      pcr_test_file_name: null,
      certificate_file: null,
      certificate_file_name: null,
    };
  },
  validations: {
    email: { email, required },
    name: { alpha, minLength: minLength(2), required },
    surname: { alpha, minLength: minLength(2), required },
    nationality_country: { required },
    residence_country: { required },
    phoneNumber: {
      numberValue: { required },
    },
    searchCodeTitle: { required },
    port_of_departure: { alphaNum, required },
    port_of_arrival: { required },
    arrival_date: { required },
    passport_number: { alphaNum, required },
    type_of_transport: { required },
    transport_number: { alphaNum, required },
    trip_target: { required },
    accommodation_address: { alphaNum, required },
  },
  computed: {
    filtredTitleCodes() {
      return this.codes.filter((el) =>
        el.title.toLowerCase().includes(this.searchCodeTitle.toLowerCase())
      );
    },
    alternateFiltredTitleCodes() {
      return this.codes.filter((el) =>
        el.title
          .toLowerCase()
          .includes(this.alternateSearchCodeTitle.toLowerCase())
      );
    },
    cssClasses() {
      return {
        'modal__button--disabled': this.verificationValidation.length !== 6,
        'phone-input__arrow--up': this.phoneInputWindow,
        'phone-input--invalid': !this.phoneNumber.numberValue,
        'alternate-phone-input__arrow--up': this.alternatePhoneInputWindow,
      };
    },
    full_name() {
      return `${this.name} ${this.surname}`;
    },
    phone_number() {
      return `${this.searchCodeTitle}${this.phoneNumber.numberValue}`;
    },
    alternative_phone_number() {
      if (this.alternatePhoneNumber.numberValue) {
        return `${this.alternateSearchCodeTitle}${this.alternatePhoneNumber.numberValue}`;
      } else {
        return `-`;
      }
    },
    foundSelectedCountryId() {
      return this.countries.find((el) => el.title === this.nationality_country)
        .id;
    },
    foundSelectedResidanceCountryId() {
      return this.countries.find((el) => el.title === this.residence_country)
        .id;
    },
    foundSelectedPortArrivalId() {
      return this.regions.find((el) => el.title === this.port_of_arrival).id;
    },
    foundSelectedTripAimlId() {
      return this.trip_aims.find((el) => el.title === this.trip_target).id;
    },
    foundSelectedTypeOfTransportlId() {
      return this.types.find((el) => el.title === this.type_of_transport).id;
    },
    verificationValidation() {
      return `${this.startInput}${this.secondInput}${this.thirdInput}`;
    },
  },
  watch: {
    surname(nV) {
      this.surname = nV.trim();
    },
    name(nV) {
      this.name = nV.trim();
    },
    passport_number(nV) {
      this.passport_number = nV.trim();
    },
    transport_number(nV) {
      this.transport_number = nV.trim();
    },
    email(nV) {
      this.email = nV.toLowerCase();
    },
    startInput(newValue, oldValue) {
      this.error = false;
      if (newValue.length > 2) {
        this.startInput = oldValue;
      }
      if (newValue.length === 2) {
        let elInp = document.getElementById('startVerifytInput');
        elInp.nextSibling.focus();
      }
    },
    secondInput(newValue, oldValue) {
      this.error = false;
      if (newValue.length > 2) {
        this.secondInput = oldValue;
      }
      if (newValue.length === 2) {
        let elInp = document.getElementById('secondVerifytInput');
        elInp.nextSibling.focus();
      }
    },
    thirdInput(newValue, oldValue) {
      this.error = false;
      if (newValue.length > 2) {
        this.thirdInput = oldValue;
      }
    },

    '$store.getters.getLeng'() {
      this.lang = this.$store.getters.getLeng;
      this.fetchAllCountries();
      this.fetchAllCodeCountries();
      this.fetchAllArrivals();
      this.fetchAllAims();
      this.fetchAllTranspotTypes();
    },
  },
  mounted() {
    this.lang = this.$store.getters.getLeng;
    this.fetchAllCountries();
    this.fetchAllCodeCountries();
    this.fetchAllArrivals();
    this.fetchAllAims();
    this.fetchAllTranspotTypes();
  },
  methods: {
    phoneInputWindowOpen() {
      this.phoneInputWindow = !this.phoneInputWindow;
      this.searchCodeTitle = '';
    },
    alternatePhoneInputWindowOpen() {
      this.alternatePhoneInputWindow = !this.alternatePhoneInputWindow;
      this.alternateSearchCodeTitle = '';
    },
    phoneInputHandler(flag) {
      this.phoneNumber.phoneCode.id = flag.id.toUpperCase();
      const serchedTitle = this.filtredTitleCodes.find(
        (el) => el.title === flag.title
      );
      this.searchCodeTitle = serchedTitle.title.slice(
        serchedTitle.title.indexOf('+')
      );
      this.phoneInputWindow = false;
    },
    alternatePhoneInputHandler(flag) {
      this.alternatePhoneNumber.phoneCode.id = flag.id.toUpperCase();
      const serchedTitle = this.alternateFiltredTitleCodes.find(
        (el) => el.title === flag.title
      );
      this.alternateSearchCodeTitle = serchedTitle.title.slice(
        serchedTitle.title.indexOf('+')
      );
      this.alternatePhoneInputWindow = false;
    },
    closeAllModal() {
      if (this.phoneInputWindow) {
        this.phoneNumber.phoneCode.id = 'UA';
        this.searchCodeTitle = '';
      }
      if (this.alternatePhoneInputWindow) {
        this.alternatePhoneNumber.phoneCode.id = 'UA';
        this.alternateSearchCodeTitle = '';
      }
      this.alternatePhoneInputWindow = false;
      this.phoneInputWindow = false;
    },
    changeUploadFile() {
      if (this.pcr_test_file) {
        this.$refs.file_pcr.value = null;
        this.pcr_test_file = null;
        this.pcr_test_file_name = null;
      } else {
        this.pcr_test_file = this.$refs.file_pcr.files[0];
        this.pcr_test_file_name = this.pcr_test_file.name;
      }
    },
    changeCertificateFile() {
      if (this.certificate_file) {
        this.$refs.file_certificate.value = null;
        this.certificate_file = null;
        this.certificate_file_name = null;
      } else {
        this.certificate_file = this.$refs.file_certificate.files[0];
        this.certificate_file_name = this.certificate_file.name;
      }
    },

    deleteFilePcrTest() {
      this.changeUploadFile();
    },

    deleteFileCertificate() {
      this.changeCertificateFile();
    },

    sendForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.openModal();
    },

    openModal() {
      this.startInput = '';
      this.secondInput = '';
      this.thirdInput = '';

      this.modal = true;
      this.sendCodeVerificationOnEmail();
    },

    async goToSuccessPdf() {
      await this.checkCodeVerification();
      this.loader = true;
      if (this.error) {
        this.loader = false;
      }

      let formData = new FormData();
      if (this.pcr_test_file) {
        formData.append('pcr_test_file', this.pcr_test_file);
      }
      if (this.certificate_file) {
        formData.append('certificate_file', this.certificate_file);
      }
      formData.append('full_name', this.full_name.toUpperCase());
      formData.append('email', this.email.toLowerCase());
      formData.append(
        'nationality_country',
        this.foundSelectedCountryId.toUpperCase()
      );
      formData.append(
        'residence_country',
        this.foundSelectedResidanceCountryId
      );
      formData.append('phone_number', this.phone_number);
      formData.append(
        'alternative_phone_number',
        this.alternative_phone_number
      );
      formData.append(
        'port_of_departure',
        this.port_of_departure.toUpperCase()
      );
      formData.append('port_of_arrival', this.foundSelectedPortArrivalId);
      formData.append('arrival_date', this.arrival_date);
      formData.append('departure_date', this.departure_date);
      formData.append('passport_number', this.passport_number.toUpperCase());
      formData.append(
        'type_of_transport',
        this.foundSelectedTypeOfTransportlId
      );
      formData.append('transport_number', this.transport_number.toUpperCase());
      formData.append('trip_aim', this.foundSelectedTripAimlId);
      formData.append('verification_code', this.verificationValidation);
      formData.append(
        'accommodation_address',
        this.accommodation_address.toUpperCase()
      );

      if (!this.error) {
        let response = await this.$store.dispatch('sendFormData', formData);
        let routeParams = {
          uuid: response.data.data.form_application.uuid,
          lang: this.$i18n.locale,
        };

        this.$router.push({
          name: 'FormApplication',
          params: routeParams,
        });
      }
    },

    async sendCodeVerificationOnEmail() {
      await this.$store.dispatch('sendCodeVerification', {
        email: this.email,
      });
    },

    async checkCodeVerification() {
      const res = await this.$store.dispatch('isValidCodeVerification', {
        email: this.email,
        verification_code: this.verificationValidation,
      });
      this.error = res.data.error;
    },
    async fetchAllCountries() {
      const res = await this.$store.dispatch('getAllCountries');
      this.countries = res;
    },
    async fetchAllCodeCountries() {
      const res = await this.$store.dispatch('getAllCodeCountries');
      this.codes = res;
    },
    async fetchAllArrivals() {
      const res = await this.$store.dispatch('getAllArrivals');
      this.regions = res;
    },
    async fetchAllAims() {
      const res = await this.$store.dispatch('getAllAims');
      this.trip_aims = res;
    },
    async fetchAllTranspotTypes() {
      const res = await this.$store.dispatch('getAllTranspotTypes');
      this.types = res;
    },
  },
};
</script>
<style lang="sass">
.phone-input-wrapper
  position: relative
  height: 50px
  width: 100%
  display: flex
  font-size: 14px
  font-weight: bold

  @media screen and (max-width: 767px)
    font-size: 12px


.phone-input-wrapper input
  width: 100%
  padding-left: 15px
  text-transform: uppercase

  @media screen and (max-width: 767px)
    font-size: 12px

.phone-input__number
  position: relative
  border: 1px solid var(--border-color-grey)
  border-left: none

  @media screen and (max-width: 767px)
    font-size: 12px

.phone-input__number::placeholder
  color: rgba(0, 0, 0, 0.5)

  @media screen and (max-width: 767px)
    font-size: 12px

.phone-input__number.invalid
  border: 2px solid #FF1919

.invalid-phone-code
  border-right: none !important

.phone-input-wrapper:hover
  background-color: rgba(124, 124, 124, 0.04)

.phone-input-wrapper input:focus
  border: 1px solid var(--btn-color-blue)

.phone-input-wrapper:after
  position: absolute
  left: 113px
  top: 11px
  content: ''
  height: 28px
  border-left: 1px solid rgba(0, 0, 0, 0.2)

.phone-input
  display: flex
  align-items: center

.phone-input__arrow,
.alternate-phone-input__arrow
  position: absolute
  left: 95px
  width: 10px
  fill: rgba(0, 0, 0, 0.2)

.phone-input__arrow--up,
.alternate-phone-input__arrow--up
  transform: rotate(180deg)

#alter-id-phone-input,
#id-phone-input
  position: relative
  display: flex
  gap: 8px
  padding: 16px 0 16px 46px
  height: 50px
  width: 113px
  border: 1px solid var(--border-color-grey)
  border-right: none
  z-index: 3

#alter-id-phone-input::placeholder,
#id-phone-input::placeholder
  color: rgba(0, 0, 0, 0.5)

#id-phone-input.invalid
  border: 2px solid #FF1919

#alter-id-phone-input:focus,
#id-phone-input:focus
  border: 1px solid var(--btn-color-blue)

.id-phone-input__flag-icon
  position: absolute
  left: 14px

.phone-input__list
  position: absolute
  top: 54px
  left: 0px
  max-height: 230px
  width: 100%
  padding: 0 14px
  border: 1px solid blue
  overflow-y: scroll
  background-color: #fff
  z-index: 5

.phone-input__list::-webkit-scrollbar
  width: 6px
  background: #EFEFF5

.phone-input__list::-webkit-scrollbar-thumb
  border-radius: 5px
  height: 80px
  background: #7C7C7C

.phone-input__list li
  display: flex
  align-items: center
  height: 38px
  padding: 0 14px
  margin: 0 -14px
  gap: 8px

.phone-input__list li:hover
  color: var(--btn-color-blue)
  cursor: pointer
  background-color: rgba(25, 101, 255, 0.04)

.modal-verification
  display: flex
  align-items: center
  flex-direction: column

.modal__title
  font-size: 20px
  font-weight: bold
  margin: 12px 0 16px

.modal__text
  max-width: 285px
  font-size: 14px
  margin-bottom: 24px
  line-height: 20px
  color: var(--border-color-grey)

.modal__email
  font-weight: bold
  color: #000

.modal__inputs
  display: flex
  gap: 24px
  position: relative

.incorect_message
  position: absolute
  bottom: 12px
  left: 106px
  font-size: 12px
  color: #FF1919

.modal__code
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  font-weight: bold
  height: 44px
  width: 80px
  border: 1px solid var(--border-color-grey)
  margin-bottom: 32px
  padding: 8px 25px
  cursor: text

  @media screen and (max-width: 475px)
    padding-left: 25px
    padding-right: 15px

.modal__code.incorrect
  border: 1px solid #FF1919

.modal__button
  display: flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  font-size: 14px
  color: #fff
  background: var(--btn-color-blue)
  width: 288px
  height: 44px
  margin-bottom: 40px
  cursor: pointer

.modal__button:hover
  background: var(--btn-color-blue-hover)

.modal__button[disabled]
  background: var(--btn-color-blue-disabled)

.modal__text-attention
  display: flex
  align-items: center
  gap: 4px
  font-size: 12px
  line-height: 18px
  color: var(--border-color-grey)

  @media screen and (max-width: 767px)
    align-items: flex-start
    line-height: 18px

.modal__text-attention-text
  position: relative
  width: 330px
  @media screen and (max-width: 450px)
    width: 220px

.modal__attention-icon
  position: absolute
  left: -16px
  top: -3px
  width: 16px
  @media screen and (max-width: 450px)
    left: -24px

.content
  flex-grow: 1

select
  color: var(--border-color-grey)
  transition: 0.3s

select:focus
  outline: none
  border: 1px solid var(--btn-color-blue-hover)

.form-wrapper
  padding: 50px 88px 80px
  border: 1px solid var(--border-color-grey)
  margin-bottom: 80px

  @media screen and (max-width: 1199px)
    padding: 32px 32px 60px
    margin-bottom: 34px

  @media screen and (max-width: 767px)
    border: none
    padding: 32px 16px 60px
    margin-bottom: 0

.form-top
  display: flex
  justify-content: space-between
  flex-wrap: nowrap
  padding-bottom: 85px
  margin-bottom: 80px
  border-bottom: 1px solid var(--border-color-grey)

  @media screen and (max-width: 1199px)
    padding-bottom: 60px
    margin-bottom: 60px

  @media screen and (max-width: 767px)
    flex-direction: column
    padding-bottom: 40px
    margin-bottom: 40px

.form__title,
.form-main__title
  font-size: 32px
  font-weight: bold
  margin-bottom: 32px
  padding-top: 30px

  @media screen and (max-width: 1199px)
    font-size: 24px
    padding-top: 16px

  @media screen and (max-width: 767px)
    font-size: 20px
    margin-bottom: 16px
    padding-top: 0
    margin-bottom: 16px

.form-main__text-attention
  display: flex
  align-items: center
  gap: 8px
  font-size: 14px
  font-weight: 500
  color: var(--border-color-grey)
  margin-bottom: 38px
.form-main__text-attention svg
  width: 16px
  height: 18px
  @media screen and (max-width: 767px)
    width: 16px
    height: 14px

.at-text
  padding-top: 3px

.form__text,
.form-main__text
  line-height: 26px
  color: var(--border-color-grey)
  margin-bottom: 42px

  @media screen and (max-width: 1199px)
    font-size: 16px

  @media screen and (max-width: 767px)
    font-size: 13.5px
    margin-bottom: 24px

.form__btns
  max-width: 942px
  width: 100%
  display: flex
  justify-content: center
  gap: 16px
  margin-top: 60px
  padding-bottom: 60px
  border-bottom: 1px solid var(--border-color-grey)
  margin: 0 auto
  @media screen and (max-width: 1199px)
    padding-bottom: 32px

.form__btns label input
  display: none

.form__btns label
  font-size: 12px
  line-height: 14px
  text-align: center
  @media screen and (max-width: 500px)
    font-size: 9px

.form__btn
  display: flex
  align-items: center
  justify-content: center
  max-width: 230px
  height: 50px
  color: #fff
  font-size: 14px
  font-weight: bold
  transition: 0.3s
  padding: 0 20px

  @media screen and (max-width: 767px)
    font-size: 12px
  @media screen and (max-width: 500px)
    padding: 0 8px
    height: 44px

.btn-add-test,
.btn-add-certificate
  width: 100%
  position: relative
  width: 232px
  height: 50px
  display: flex
  justify-content: center
  @media screen and (max-width: 500px)
    height: 44px

.btn-add-test
  background: var(--primary-color-light-grey)

.btn-add-certificate
  background: var(--primary-color-yellow)

.btn-add-certificate[disabled]
  border: 1px solid #39CF75

.btn-add-test[disabled]
  border: 1px solid #39CF75

.form__btn--add-test,
.form__btn--add-certificate
  font-size: 12px
  color: #000
  max-width: 232px
  width: 100%
  position: relative
  transition: 0.3s
  cursor: pointer
  text-transform: uppercase

.form__btn--add-test span
  position: relative
  margin: 0 auto

.form__btn--add-test[disabled],
.form__btn--add-certificate[disabled]
  justify-content: flex-start
  position: absolute
  display: block
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  top: 19px
  background-color: transparent
  max-width: 207px

  @media screen and (max-width: 500px)
    max-width: 183px
    top: 15px
  @media screen and (max-width: 460px)
    max-width: 160px
  @media screen and (max-width: 425px)
    max-width: 113px

.form__btn--add-test[disabled]:hover,
.form__btn--add-certificate[disabled]:hover
  background-color: transparent
  cursor: default

.form__btn--add-test:hover
  background: #A4C7FD

.form__btn--add-test:active
  background: #B9D4FF

.form__btn--add-certificate
  width: 100%
  max-width: 232px
  background: var(--primary-color-yellow)

.form__btn--add-certificate:hover
  background: #E8DD07

.form__btn--add-certificate:active
  background: #F3E80B

.form__sign-contract
  margin: 0 auto
  margin-top: 60px
  @media screen and (max-width: 1199px)
    margin-top: 32px
  @media screen and (max-width: 500px)
    display: flex
    width: 100%

.form__btn-sign-contract
  display: flex
  align-items: center
  justify-content: center
  height: 50px
  font-size: 14px
  width: 220px
  font-weight: 600
  @media screen and (max-width: 1199px)
    width: 200px
  @media screen and (max-width: 500px)
    height: 44px
    width: 100% !important

.form__btn-sign-contract
  background: var(--btn-color-blue)
  color: #fff

.form__btn-sign-contract:hover
  background: var(--btn-color-blue-hover)

.form__btn-sign-contract:active
  background: var(--btn-color-blue-focus)

.deleted-cross
  position: absolute
  top: 0
  right: 0
  padding: 25px
  @media screen and (max-width: 500px)
    padding: 25px 20px

.deleted-cross:before,
.deleted-cross:after
  position: absolute
  transform: rotate(45deg)
  content: ''
  top: 17px
  right: 22px
  height: 16px
  width: 2px
  background: #000
  cursor: pointer
  @media screen and (max-width: 500px)
    right: 17px
    top: 14px
    height: 14px
  @media screen and (max-width: 350px)
    height: 12px
    right: 14px
    top: 15px

.deleted-cross:after
  transform: rotate(-45deg)

.deleted-cross:hover.deleted-cross:after,
.deleted-cross:hover.deleted-cross:before
  background: var(--btn-color-blue)

.form-main
  display: flex
  flex-direction: column
  align-items: center

.form-main__title
  text-align: center
  max-width: 370px
  margin-bottom: 24px

  @media screen and (max-width: 767px)
    max-width: 320px
    margin-bottom: 16px

.form-main__title--pointer
  position: relative

.form-main__title--pointer:after
  position: absolute
  content: ''
  left: -24px
  top: 14px
  width: 10px
  height: 10px
  background: #006EFF
  border-radius: 50%
  @media screen and (max-width: 1199px)
    left: -24px
    top: 10px
  @media screen and (max-width: 767px)
    top: 7px
    left: -18px

.form-main__text
  text-align: center
  max-width: 600px

  @media screen and (max-width: 1199px)
    max-width: 500px

  @media screen and (max-width: 767px)
    max-width: 520px

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none

.form-main-fields
  position: relative
  display: flex
  width: 100%
  flex-direction: column

.form-main-fields__inputs-grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  width: 100%
  column-gap: 16px
  row-gap: 42px
  padding: 0 0 32px 0
  max-width: 942px
  margin: 0 auto

  @media screen and (max-width: 767px)
    grid-template-columns: repeat(1, 1fr)
    row-gap: 32px

.form-main-fields__inputs-grid:nth-child(-n+2)
  margin-bottom: 32px
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  @media screen and (max-width: 767px)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    margin-bottom: 24px

.form-main-fields__inputs
  position: relative
  height: 50px
  width: 100%
  padding: 16px 14px
  text-transform: uppercase
  border: 1px solid var(--border-color-grey)
  font-size: 14px
  font-weight: bold

  @media screen and (max-width: 767px)
    font-size: 12px
  @media screen and (max-width: 767px)
    height: 44px

.form-main-fields__inputs.invalid
  border: 2px solid red

.form-main-fields__inputs:hover
  background: rgba(124, 124, 124, 0.04)

.form-main-fields__inputs:focus
  border: 1px solid var(--btn-color-blue)

.form-main-fields__country-input
  position: relative

.form-main-fields__country-input:after
  display: flex
  position: absolute
  content: 'Select country'
  color: var(--border-color-grey)
  font-size: 14px
  top: 50%
  transform: translateY(-50%)
  right: 36px
  z-index: -1

  @media screen and (max-width: 767px)
    font-size: 10px
    right: 30px
    top: 50%

.form-main-fields__inputs-ticket-grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  column-gap: 16px
  row-gap: 42px
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
  padding: 0 0 32px 0
  margin-bottom: 32px
  max-width: 942px
  margin: 0 auto

  @media screen and (max-width: 767px)
    grid-template-rows: 1fr 1fr 1fr 1fr
    margin-bottom: 24px
    row-gap: 24px

.date-arrival-picker-input,
.date-departure-picker-input
  @media screen and (max-width: 1199px)
    display: none
  @media screen and (max-width: 767px)
    display: block

.date-arrival-picker-input--small,
.date-departure-picker-input--small
  display: none
  @media screen and (max-width: 1199px)
    display: block
  @media screen and (max-width: 767px)
    display: none

.optional
  position: absolute
  content: ''
  left: 0px
  bottom: -20px
  font-size: 12px
  font-style: italic
  font-weight: normal !important
  color: rgba(0, 0, 0, 0.3)

  @media screen and (max-width: 767px)
    bottom: -16px

.input__name,
.input__surname,
.input__email,
.input__nationality-wrapper,
.input__residence-wrapper,
.port-input,
.port-arrival-input,
.date-arrival-picker-input--small,
.date-arrival-picker-input,
.passport-input,
.transport-type-input,
.flight-input,
.trip__input,
.accommodation__input,
.departure-input
  position: relative

.form-main-fields__inputs::placeholder
  color: rgba(0, 0, 0, 0.5) !important

.input__residence-wrapper .autocomplete-box.invalid .v-autocomplete .v-input__slot,
.port-arrival-input .autocomplete-box.invalid .v-autocomplete .v-input__slot,
.trip__input .autocomplete-box.invalid .v-autocomplete .v-input__slot,
.transport-type-input .autocomplete-box.invalid .v-autocomplete .v-input__slot,
.date-arrival-picker-input.invalid .mx-input,
.date-arrival-picker-input--small.invalid .mx-input,
.input__nationality-wrapper .autocomplete-box.invalid .v-autocomplete .v-input__slot
  height: 46px
  border: none

.invalid
  border: 2px solid #FF1919

.fill-error
  position: absolute
  content: ''
  top: 56px
  left: 0
  font-size: 12px
  font-weight: normal
  color: #FF1919
</style>
