<template>
  <div class="autocomplete-box">
    <v-autocomplete
      v-model="selectedItem"
      :items="items"
      item-text="title"
      :attach="appendTo"
      disable-lookup
      :placeholder="placeholder"
      @input="$emit('select')"
      @keydown.enter="$event.target.blur()"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    appendTo: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isConutryCode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
<style lang="sass">
// .autocomplete-box .v-text-field.v-input--is-focused #input-24::-webkit-input-placeholder
  // color: #000 !important

.v-autocomplete img
  height: 20px
  width: 20px

.autocomplete-box
  position: relative

.autocomplete-box .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.autocomplete-box .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after
  display: none

.autocomplete-box .v-select__selections
  gap: 8px

.autocomplete-box .v-select__selections input::placholder
  color: #000 !important

.v-autocomplete .v-input__icon
  position: relative

.v-autocomplete.v-select .v-input__icon:before
  background-image: url('/img/select-caret.svg')
  position: absolute
  content: ''
  top: 50%
  left: 85%
  width: 11px
  height: 7px
  transform: translate(-50%, -50%)
  transition: var(--transition-speed)

.v-autocomplete.v-select--is-menu-active .v-input__icon:before
  transform: translate(-50%, -50%) rotate(180deg)

.v-autocomplete .v-input__slot
  border: 1px solid var(--border-color-grey)
  width: inherit
  height: 50px
  padding: 0px 14px
  font-size: 14px
  font-weight: bold
  margin-bottom: 0
  transition: none

  @media screen and (max-width: 768px)
    font-size: 12px

.autocomplete-box .v-text-field:hover
  background: rgba(124, 124, 124, 0.04)

.autocomplete-box .v-text-field.v-input--is-focused .v-input__slot
  position: relative
  border: 1px solid var(--btn-color-blue)

.v-select.v-text-field input::placeholder
  color: rgba(0, 0, 0, 0.5) !important

.v-autocomplete.v-input
  padding-top: 0
  margin-top: 0

.v-autocomplete .v-text-field__details
  display: none

.v-autocomplete .v-input__slot .v-select__slot input
  text-transform: uppercase

.theme--light.v-list-item.v-list-item--active::before
  opacity: 1!important
  background-color: transparent

.autocomplete-box .v-menu__content
  box-shadow: none !important
  border: 1px solid #1965FF

.v-autocomplete .v-list
  padding: 0

.autocomplete-box .v-list-item
  min-height: 100%!important

.autocomplete-box .v-list-item__title
  font-size: 14px !important
  font-weight: bold
  text-align: left

.autocomplete-box .v-list-item .v-list-item__content
  padding: 10px 0

.autocomplete-box .v-list-item__content:hover
  color: var(--btn-color-blue)

.autocomplete-box .v-list-item--active .v-list-item__content
  position: relative

.autocomplete-box .v-list-item--active .v-list-item__content
  position: relative
  background-color: rgba(25, 101, 255, 0.04)
  padding: 10px 16px
  margin: 0 -16px
.autocomplete-box .v-list-item--active .v-list-item__content:after
  content: ""
  position: absolute
  top: 6px
  right: 15px
  width: 21px
  height: 19px
  background-image: url(/img/done-blue.svg)

.v-list-item--active .v-list-item__content .v-list-item__title
  color: var(--btn-color-blue)
  font-weight: bold

.v-menu__content::-webkit-scrollbar
  width: 6px
  background: #EFEFF5

.v-menu__content::-webkit-scrollbar-thumb
  background: #7C7C7C

.v-menu__content .theme--light.v-list-item .v-list-item__mask
  background: rgba(25, 101, 255, 0.05)
  color: var(--btn-color-blue)
</style>
